import React from 'react';
import useMenuChange from './handler';


const styles = [
    "transition duration-300 ease-in-out",
    "-translate-y-full transition delay-200 duration-500 ease-in-out" 
];
let currentStyle = styles[1];


const BurgerMenu = ({ items }) => {

    const [ open, change ] = useMenuChange()

    if (open) {
        currentStyle = styles[0]
    }
    else {
        currentStyle = styles[1]
    }
    //console.log ("currentStyle: ", {currentStyle})

    const newItems = new Array()

    items.forEach(listItem => {
        const newListItem = React.cloneElement(listItem, { onClick: change})
        newItems.push(newListItem)        
    });

  return (
    <div className={'fixed top-10 w-full md:hidden z-10' + ' ' + currentStyle}>
        <div className='relative container mx-auto px-4 xl:px-20'>
            <ul className='bg-white grid grid-cols-1 gap-4 pt-4 pb-4'>
                {newItems}
            </ul>
        </div>
    </div>
  )
}


export default BurgerMenu