import React from 'react'
import Navigation from './navigation'
import Footer from './footer'

const Layout = ({ children }) => {
  return (
    <div>
      <Navigation />
      <div className='relative container mx-auto px-4 xl:px-20 pt-10 pb-10 md:pt-20'>
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout