import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import Burger from "./burger-menu/burger";
import BurgerMenu from "./burger-menu/menu";
import Logo from "./logo";

function compareMenuItems( a, b ) {
    if ( a.order < b.order ){
      return -1;
    }
    if ( a.order > b.order ){
      return 1;
    }
    return 0;
  }

const Navigation = () => {
    const data = useStaticQuery(graphql`
        query NavigationQuery{
            allMarkdownRemark {
                edges {
                    node {                
                        frontmatter {
                            title
                            url
                            order
                        }
                    }
                }
            }
        }
    `)

    const nodes = Object.entries(data.allMarkdownRemark.edges)
    const menu = new Array()

    for (const [key, value] of nodes) {
        const entry = new Object
        const frontmatter = value?.node?.frontmatter
        entry.title = frontmatter?.title
        entry.url = frontmatter?.url
        entry.order = frontmatter?.order
        if (entry.order !== "0") {
            menu.push(entry)
        }
    }

    menu.sort(compareMenuItems)

    //console.log("Menu:", menu)
  
    const items = new Array()

    menu.forEach(item => {
        if (item.url.match(/^\s?http(s?)/gi)) {
            items.push(
                    <li key={item.order} className="text-base" role="menuitem">
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                            {item.title}
                        </a>
                    </li>
                )
            } else {
            items.push(
                    <li key={item.order} className="text-base" role="menuitem">
                        <div>
                            <Link activeClassName="text-neutral-950" className='block w-full text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 text-3xl md:text-2xl font-opensans ease-in-out duration-300' to={item.url} >
                                {item.title}
                            </Link>
                        </div>
                    </li>
                )
            }
        }
    )    

    
    return (
        <>
            <div className="fixed top-0 w-full flex items-center h-auto bg-neutral-100 py-2 text-neutral-500 z-20">
                <div className="relative container flex flex-row mx-auto items-center px-4 xl:px-20">
                        <Burger/>
                        <div className="hidden md:inline-block basis-1/2 align-middle">
                            <ul className="flex flex-row gap-8">{items}</ul>
                        </div>
                        <div className="basis-1/2 grid justify-end">
                            <div className="block w-full"></div>
                            <Logo/>
                        </div>
                </div>
            </div>
            <BurgerMenu items={items}/>
        </>
    )
}




  
export default Navigation