import React from "react"

const Footer = () => {
    return (
      <footer className={`fixed bg-neutral-100 bottom-0 flex items-center font-opensans text-neutral-500 h-8 w-full`}>
        <div className="container grid grid-cols-2 m-auto px-4 xl:px-20">
          <div className="grid grid-cols-1 py-1">
              <p className="justify-self-start">
              © {new Date().getFullYear()} LITC
              </p>
          </div>
          <div className="grid grid-cols-1 py-1">
              <p className="justify-self-end">An <a className="text-neutral-500" href="https://lewis.enterprises" target="_blank" rel="noreferrer">LE</a> brand</p>
          </div>
        </div>
      </footer>
    )
  }
  
  export default Footer